export function getVisibleSettings(allSettings, visibleSettings, readOnlySettings = []) {
  let settings = []
  visibleSettings?.forEach(settingKey => {
    const setting = allSettings?.find(s => s.key === settingKey)
    if (setting) {
      setting.readOnly = !!readOnlySettings.find(rs => rs === settingKey)
      settings.push(setting)
    }
  })
  return settings
}

export function getSettingByKey(listOfSettings, key) {
  if (listOfSettings?.length > 0) {
    const setting = listOfSettings.find((s) => s.translationKey === key)
    if (setting?.customerSettingValue) {
      return setting
    }
  }
  return null
}
